// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'
import * as vars from '@/vars.json'
import axios from 'axios'
import moment from 'moment'
var accounting = require('accounting')
var functions = require('../../../functions/functions')

const httpAxios = axios.create({
  params: {},
})

export const factura = {
  namespaced: true,
  state: {
    facturaSaleLst: [],
    facturaSale2Lst: [],
    noteSaleLst: [],
    noteSale2Lst: [],
    facturaPurchaseLst: [],
    notePurchaseLst: [],
    regenerateBillingsLst: [],
    selfConsumptionLst: [],
    lstProductGroupReport: [],
  },
  mutations: {
    LOAD_FACTURA_SALE_LST: (state, data) => {
      state.facturaSaleLst = data
    },
    LOAD_FACTURA_SALE_2_LST: (state, data) => {
      state.facturaSale2Lst = data
    },
    LOAD_NOTE_SALE_LST: (state, data) => {
      state.noteSaleLst = data
    },
    LOAD_NOTE_SALE_2_LST: (state, data) => {
      state.noteSale2Lst = data
    },
    LOAD_FACTURA_PURCHASE_LST: (state, data) => {
      state.facturaPurchaseLst = data
    },
    LOAD_NOTE_PURCHASE_LST: (state, data) => {
      state.notePurchaseLst = data
    },
    LOAD_REGENERATE_BILLINGS_LST: (state, data) => {
      state.regenerateBillingsLst = data
    },
    LOAD_REGENERATE_BILLINGS_2_LST: (state, data) => {
      state.regenerateBillings2Lst = data
    },
    LOAD_SELF_CONSUMPTION_LST: (state, data) => {
      state.selfConsumptionLst = data
    },
    LOAD_PRODUCT_GROUP_LST: (state, data) => {
      state.lstProductGroupReport = data
    },
  },
  actions: {
    setNoteSale ({ state, rootState }, [noteData, secnotesale]) {
      return new Promise((resolve, reject) => {
        const dataNote = {
          noteData: noteData,
          uidUser: rootState.user.user.uid,
          secnotesale: secnotesale,
        }
        httpAxios.post(vars.url + '/addSaleNote', dataNote).then((response) => {
          if (response.status.toString() === '200') {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    setFacturaSale ({ state, rootState }, [facturaData, secfacsale]) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.user.user.uid,
          secfacsale: secfacsale,
        }
        httpAxios.post(vars.url + '/addSaleFactura', dataFactura).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    setSelfConsumption ({ state, rootState }, [facturaData, secfacsale]) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.user.user.uid,
          secfacsale: secfacsale,
        }
        httpAxios.post(vars.url + '/addSelfConsumption', dataFactura).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    setFacturaSaleAccounts ({ state, rootState }, data) {
      return new Promise((resolve, reject) => {
        for (let i = 0; i < data.length; i++) {
          data[i].uidUser = rootState.user.user.uid
        }
        httpAxios.post(vars.url + '/addSaleFacturaAccounts', data).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },

    sendFacSrir ({ state, rootState }, facturaData) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.user.user.uid,
        }
        httpAxios.post(vars.url + '/sendFacSrir', dataFactura).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    recreateFacSrir ({ state, rootState }, facturaData) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.user.user.uid,
        }
        httpAxios.post(vars.url + '/recreateFacSrir', dataFactura).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    sendFacUserMail ({ state, rootState }, [facturaData, aditionalField]) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.user.user.uid,
          aditionalField: aditionalField,
        }
        httpAxios.post(vars.url + '/sendUserMail', dataFactura).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    payFacturaPurchaes ({ state, rootState }, facturaData) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.user.user.uid,
        }
        const months = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE']
        const date = new Date()
        const data = {
          date: moment(new Date()).format('YYYY-MM-DD'),
          month: months[date.getMonth()],
          description: dataFactura.facturaData.descripcion,
          amount: dataFactura.facturaData.total,
          // patient: this.valuePatient.data.data.names + ' ' + this.valuePatient.data.data.lastNames,
          created: new Date().getTime(),
          documentId: dataFactura.facturaData.id,
        }
        const keyEgress = firebase.database().ref().child(`financial/${dataFactura.uidUser}/egress/`).push().key
        const updateEgress = {}
        data.id = keyEgress
        updateEgress[`financial/${dataFactura.uidUser}/egress/${keyEgress}`] = data
        firebase.database().ref().update(updateEgress)
        firebase.database().ref(`facturapurchase/${dataFactura.uidUser}/${dataFactura.facturaData.id}/pendientepago`).set(false).then(res => {
          resolve(true)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    payFacturaSale ({ state, rootState }, facturaData) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.user.user.uid,
        }
        const months = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE']
        const date = new Date()
        const data = {
          date: moment(new Date()).format('YYYY-MM-DD'),
          month: months[date.getMonth()],
          description: dataFactura.facturaData.descripcion,
          amount: dataFactura.facturaData.total,
          // patient: this.valuePatient.data.data.names + ' ' + this.valuePatient.data.data.lastNames,
          created: new Date().getTime(),
          documentId: dataFactura.facturaData.id,
        }
        const keyEntry = firebase.database().ref().child(`financial/${dataFactura.uidUser}/entry/`).push().key
        const updateEntry = {}
        data.id = keyEntry
        updateEntry[`financial/${dataFactura.uidUser}/entry/${keyEntry}`] = data
        firebase.database().ref().update(updateEntry)
        firebase.database().ref(`facturasale/${dataFactura.uidUser}/${dataFactura.facturaData.id}/pendientepago`).set(false).then(res => {
          resolve(true)
        }).catch((error) => {
          reject(error)
        })
        firebase.database().ref(`facturasale/${dataFactura.uidUser}/${dataFactura.facturaData.id}/fechapago`).set(
          facturaData.fechapago,
        ).then(res => {
          resolve(true)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    payNoteSale ({ state, rootState }, facturaData) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.user.user.uid,
        }
        const months = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE']
        const date = new Date()
        const data = {
          date: moment(new Date()).format('YYYY-MM-DD'),
          month: months[date.getMonth()],
          description: dataFactura.facturaData.descripcion,
          amount: dataFactura.facturaData.total,
          // patient: this.valuePatient.data.data.names + ' ' + this.valuePatient.data.data.lastNames,
          created: new Date().getTime(),
          documentId: dataFactura.facturaData.id,
        }
        const keyEntry = firebase.database().ref().child(`financial/${dataFactura.uidUser}/entry/`).push().key
        const updateEntry = {}
        data.id = keyEntry
        updateEntry[`financial/${dataFactura.uidUser}/entry/${keyEntry}`] = data
        firebase.database().ref().update(updateEntry)
        firebase.database().ref(`notesale/${dataFactura.uidUser}/${dataFactura.facturaData.id}/pendientepago`).set(false).then(res => {
          resolve(true)
        }).catch((error) => {
          reject(error)
        })
        firebase.database().ref(`notesale/${dataFactura.uidUser}/${dataFactura.facturaData.id}/fechapago`).set(
          facturaData.fechapago,
        ).then(res => {
          resolve(true)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    anulFacturaSale ({ state, rootState }, facturaData) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.user.user.uid,
        }
        httpAxios.post(vars.url + '/anulSaleFactura', dataFactura).then((response) => {
          if (response.status.toString() === '200') {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    anulSelfConsumption ({ state, rootState }, facturaData) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.user.user.uid,
        }
        httpAxios.post(vars.url + '/anulSelfConsumption', dataFactura).then((response) => {
          if (response.status.toString() === '200') {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    anulNoteSale ({ state, rootState }, noteData) {
      return new Promise((resolve, reject) => {
        const dataNote = {
          noteData: noteData,
          uidUser: rootState.user.user.uid,
        }
        httpAxios.post(vars.url + '/anulSaleNote', dataNote).then((response) => {
          if (response.status.toString() === '200') {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    setFacturaPurchase ({ state, rootState }, facturaData) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.user.user.uid,
        }
        httpAxios.post(vars.url + '/addPurchaseFactura', dataFactura).then((response) => {
          if (response.status.toString() === '200') {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    setNotePurchase ({ state, rootState }, [noteData, secnotepurchase]) {
      return new Promise((resolve, reject) => {
        const dataNote = {
          noteData: noteData,
          uidUser: rootState.user.user.uid,
          secnotepurchase: secnotepurchase,
        }
        httpAxios.post(vars.url + '/addPurchaseNote', dataNote).then((response) => {
          if (response.status.toString() === '200') {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    anulFacturaPurchase ({ state, rootState }, facturaData) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.user.user.uid,
        }
        httpAxios.post(vars.url + '/anulPurchaseFactura', dataFactura).then((response) => {
          if (response.status.toString() === '200') {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    anulNotePurchase ({ state, rootState }, noteData) {
      return new Promise((resolve, reject) => {
        const dataNote = {
          noteData: noteData,
          uidUser: rootState.user.user.uid,
        }
        httpAxios.post(vars.url + '/anulPurchaseNote', dataNote).then((response) => {
          if (response.status.toString() === '200') {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    loadSelfConsumption ({ commit, rootState }, branch) {
      if (rootState.user.user) {
        firebase.database().ref(`selfConsumption/${rootState.user.user.uid}`).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              if (branch) {
                arr = arr.filter(item => {
                  if (branch.id.toString() === '-1') {
                    return !item.branch || item.branch.id.toString() === '-1'
                  } else {
                    return item.branch ? item.branch.id.toString() === branch.id.toString() : false
                  }
                })
              }
              commit('LOAD_SELF_CONSUMPTION_LST', arr.reverse())
            } else {
              commit('LOAD_SELF_CONSUMPTION_LST', [])
            }
          } else {
            commit('LOAD_SELF_CONSUMPTION_LST', [])
          }
        })
      }
    },
    loadFacturaSale ({ commit, rootState }, branch) {
      commit('LOAD_REGENERATE_BILLINGS_LST', [])
      if (rootState.user.user) {
        // var date = new Date()
        var firstDay = moment(new Date()).format('YYYY-MM-DD')
        var lastDay = moment(new Date()).format('YYYY-MM-DD')
        firebase.database().ref(`facturasale/${rootState.user.user.uid}`).orderByChild('fechaEmision')
          .startAt(firstDay).endAt(lastDay).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              if (branch) {
                arr = arr.filter(item => {
                  if (branch.id.toString() === '-1') {
                    return !item.branch || item.branch.id.toString() === '-1'
                  } else {
                    return item.branch ? item.branch.id.toString() === branch.id.toString() : false
                  }
                })
              }
              const arrRegenerateFac = []
              commit('LOAD_FACTURA_SALE_LST', arr.reverse())
              for (let i = 0; i < arr.length; i++) {
                if (!arr[i].estadoProceso && arr[i].estado) {
                  arrRegenerateFac.push(arr[i])
                }
              }
              commit('LOAD_REGENERATE_BILLINGS_LST', arrRegenerateFac)
            } else {
              commit('LOAD_FACTURA_SALE_LST', [])
              commit('LOAD_REGENERATE_BILLINGS_LST', [])
            }
          } else {
            commit('LOAD_FACTURA_SALE_LST', [])
            commit('LOAD_REGENERATE_BILLINGS_LST', [])
          }
        })
      }
    },
    loadFacturaSale2 ({ commit, rootState }, branch) {
      commit('LOAD_REGENERATE_BILLINGS_2_LST', [])
      if (rootState.user.user) {
        // var date = new Date()
        var firstDay = moment(new Date()).format('YYYY-MM-DD')
        var lastDay = moment(new Date()).format('YYYY-MM-DD')
        firebase.database().ref(`facturasale2/${rootState.user.user.uid}`).orderByChild('fechaEmision')
          .startAt(firstDay).endAt(lastDay).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              if (branch) {
                arr = arr.filter(item => {
                  if (branch.id.toString() === '-1') {
                    return !item.branch || item.branch.id.toString() === '-1'
                  } else {
                    return item.branch ? item.branch.id.toString() === branch.id.toString() : false
                  }
                })
              }
              const arrRegenerateFac = []
              commit('LOAD_FACTURA_SALE_2_LST', arr.reverse())
              for (let i = 0; i < arr.length; i++) {
                if (!arr[i].estadoProceso && arr[i].estado) {
                  arrRegenerateFac.push(arr[i])
                }
              }
              commit('LOAD_REGENERATE_BILLINGS_2_LST', arrRegenerateFac)
            } else {
              commit('LOAD_FACTURA_SALE_2_LST', [])
              commit('LOAD_REGENERATE_BILLINGS_2_LST', [])
            }
          } else {
            commit('LOAD_FACTURA_SALE_2_LST', [])
            commit('LOAD_REGENERATE_BILLINGS_2_LST', [])
          }
        })
      }
    },
    loadProductsReportFac ({ commit, rootState }, { firstDay, lastDay, type }) {
      return new Promise(resolve => {
        if (rootState.user.user) {
          const arrTotalFac = []
          const arrTotalProducts = []
          firebase.database().ref(`facturasale/${rootState.user.user.uid}`).orderByChild('fechaEmision')
            .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
            if (snapshot) {
              let arr = []
              const res = snapshot.val()
              if (res) {
                arr = Object.keys(res).map(cou => {
                  res[cou].id = cou
                  return res[cou]
                })
              }
              for (let i = 0; i < arr.length; i++) {
                if (arr[i].estado) {
                  arrTotalFac.push(arr[i])
                }
              }
            }
            firebase.database().ref(`facturasale2/${rootState.user.user.uid}`).orderByChild('fechaEmision')
              .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
              if (snapshot) {
                let arr = []
                const res = snapshot.val()
                if (res) {
                  arr = Object.keys(res).map(cou => {
                    res[cou].id = cou
                    return res[cou]
                  })
                }
                for (let i = 0; i < arr.length; i++) {
                  if (arr[i].estado) {
                    arrTotalFac.push(arr[i])
                  }
                }
              }
              firebase.database().ref(`notesale/${rootState.user.user.uid}`).orderByChild('fechaEmision')
                .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
                if (snapshot) {
                  let arr = []
                  const res = snapshot.val()
                  if (res) {
                    arr = Object.keys(res).map(cou => {
                      res[cou].id = cou
                      return res[cou]
                    })
                  }
                  for (let i = 0; i < arr.length; i++) {
                    if (arr[i].estado) {
                      arrTotalFac.push(arr[i])
                    }
                  }
                }
                firebase.database().ref(`notesale2/${rootState.user.user.uid}`).orderByChild('fechaEmision')
                  .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
                  if (snapshot) {
                    let arr = []
                    const res = snapshot.val()
                    if (res) {
                      arr = Object.keys(res).map(cou => {
                        res[cou].id = cou
                        return res[cou]
                      })
                    }
                    for (let i = 0; i < arr.length; i++) {
                      if (arr[i].estado) {
                        arrTotalFac.push(arr[i])
                      }
                    }
                  }
                  for (let i = 0; i < arrTotalFac.length; i++) {
                    for (let j = 0; j < arrTotalFac[i].lstProductos.length; j++) {
                      let total = null
                      const valMult = 1 + (parseFloat(arrTotalFac[i].lstProductos[j].producto.tarifaiva.valor) / 100)
                      if (arrTotalFac[i].lstProductos[j].producto.tarifaiva.valor.toString() !== '0') {
                        total = accounting.toFixed((parseFloat(arrTotalFac[i].lstProductos[j].total) * parseFloat(valMult)), 3)
                      } else {
                        total = arrTotalFac[i].lstProductos[j].total
                      }
                      if (arrTotalFac[i].lstProductos[j].producto.lstProducts) {
                        if (arrTotalFac[i].lstProductos[j].producto.lstProducts.length > 0) {
                          for (let k = 0; k < arrTotalFac[i].lstProductos[j].producto.lstProducts.length; k++) {
                            let totalSub = null
                            const valMultSub = 1 + (parseFloat(arrTotalFac[i].lstProductos[j].producto.lstProducts[k].product.tarifaiva.valor) / 100)
                            if (arrTotalFac[i].lstProductos[j].producto.lstProducts[k].product.tarifaiva.valor.toString() !== '0') {
                              totalSub = accounting.toFixed(((parseFloat(arrTotalFac[i].lstProductos[j].producto.lstProducts[k].quantity) * parseFloat(arrTotalFac[i].lstProductos[j].cantidad)) * parseFloat(arrTotalFac[i].lstProductos[j].producto.lstProducts[k].product.activeValue) * parseFloat(valMultSub)), 3)
                            } else {
                              totalSub = accounting.toFixed(parseFloat(arrTotalFac[i].lstProductos[j].producto.lstProducts[k].quantity) * parseFloat(arrTotalFac[i].lstProductos[j].producto.lstProducts[k].product.activeValue), 3)
                            }
                            const objProductSub = {
                              cantidad: accounting.toFixed(parseFloat(arrTotalFac[i].lstProductos[j].producto.lstProducts[k].quantity) * parseFloat(arrTotalFac[i].lstProductos[j].cantidad)),
                              producto: arrTotalFac[i].lstProductos[j].producto.lstProducts[k].product,
                              total: totalSub,
                              idProduct: arrTotalFac[i].lstProductos[j].producto.lstProducts[k].product.id,
                            }
                            if (type.code === '001' || type.code === '003') {
                              arrTotalProducts.push(objProductSub)
                            }
                          }
                        }
                      }
                      const objProduct = {
                        cantidad: arrTotalFac[i].lstProductos[j].cantidad,
                        producto: arrTotalFac[i].lstProductos[j].producto,
                        total: total,
                        idProduct: arrTotalFac[i].lstProductos[j].producto.id,
                      }
                      if (type.code === '001' || type.code === '002') {
                        arrTotalProducts.push(objProduct)
                      }
                    }
                  }
                  const result = functions.groupBy(arrTotalProducts, product => product.idProduct)
                  const arrGroupKeys = Array.from(result.keys())
                  const arrTotalProduct = []
                  for (let i = 0; i < arrGroupKeys.length; i++) {
                    const arrProdGroup = result.get(arrGroupKeys[i].toString())
                    let cantidad = 0
                    let total = 0
                    for (let j = 0; j < arrProdGroup.length; j++) {
                      cantidad = parseFloat(cantidad) + parseFloat(arrProdGroup[j].cantidad)
                      total = parseFloat(total) + parseFloat(arrProdGroup[j].total)
                    }
                    const objPush = {
                      cantidad: accounting.toFixed(cantidad, 3),
                      producto: arrProdGroup[0].producto,
                      total: accounting.toFixed(total, 3),
                      idProduct: arrProdGroup[0].producto.id,
                    }
                    arrTotalProduct.push(objPush)
                  }
                  commit('LOAD_PRODUCT_GROUP_LST', arrTotalProduct)
                  resolve(true)
                })
              })
            })
          })
        }
      })
    },
    loadFacturaSaleFilterDate ({ commit, rootState }, { firstDay, lastDay, branch }) {
      return new Promise(resolve => {
        if (rootState.user.user) {
          firebase.database().ref(`facturasale/${rootState.user.user.uid}`).orderByChild('fechaEmision')
            .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
            if (snapshot) {
              let arr = []
              const res = snapshot.val()
              if (res) {
                arr = Object.keys(res).map(cou => {
                  res[cou].id = cou
                  return res[cou]
                })
                if (branch) {
                  arr = arr.filter(item => {
                    if (branch.id.toString() === '-1') {
                      return !item.branch || item.branch.id.toString() === '-1'
                    } else {
                      return item.branch ? item.branch.id.toString() === branch.id.toString() : false
                    }
                  })
                }
                commit('LOAD_FACTURA_SALE_LST', arr.reverse())
                resolve(arr.reverse())
              } else {
                commit('LOAD_FACTURA_SALE_LST', [])
                resolve([])
              }
            } else {
              commit('LOAD_FACTURA_SALE_LST', [])
              resolve([])
            }
          })
        }
      })
    },
    loadFacturaSaleFilterDate2 ({ commit, rootState }, { firstDay, lastDay, branch }) {
      if (rootState.user.user) {
        firebase.database().ref(`facturasale2/${rootState.user.user.uid}`).orderByChild('fechaEmision')
          .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              if (branch) {
                arr = arr.filter(item => {
                  if (branch.id.toString() === '-1') {
                    return !item.branch || item.branch.id.toString() === '-1'
                  } else {
                    return item.branch ? item.branch.id.toString() === branch.id.toString() : false
                  }
                })
              }
              commit('LOAD_FACTURA_SALE_2_LST', arr.reverse())
            } else {
              commit('LOAD_FACTURA_SALE_2_LST', [])
            }
          } else {
            commit('LOAD_FACTURA_SALE_2_LST', [])
          }
        })
      }
    },
    loadNoteSale ({ commit, rootState }, branch) {
      if (rootState.user.user) {
        // var date = new Date()
        var firstDay = moment(new Date()).format('YYYY-MM-DD')
        var lastDay = moment(new Date()).format('YYYY-MM-DD')
        firebase.database().ref(`notesale/${rootState.user.user.uid}`).orderByChild('fechaEmision')
          .startAt(firstDay).endAt(lastDay).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              if (branch) {
                arr = arr.filter(item => {
                  if (branch.id.toString() === '-1') {
                    return !item.branch || item.branch.id.toString() === '-1'
                  } else {
                    return item.branch ? item.branch.id.toString() === branch.id.toString() : false
                  }
                })
              }
              commit('LOAD_NOTE_SALE_LST', arr.reverse())
            } else {
              commit('LOAD_NOTE_SALE_LST', [])
            }
          } else {
            commit('LOAD_NOTE_SALE_LST', [])
          }
        })
      }
    },
    loadNoteSale2 ({ commit, rootState }, branch) {
      if (rootState.user.user) {
        // var date = new Date()
        var firstDay = moment(new Date()).format('YYYY-MM-DD')
        var lastDay = moment(new Date()).format('YYYY-MM-DD')
        firebase.database().ref(`notesale2/${rootState.user.user.uid}`).orderByChild('fechaEmision')
          .startAt(firstDay).endAt(lastDay).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              if (branch) {
                arr = arr.filter(item => {
                  if (branch.id.toString() === '-1') {
                    return !item.branch || item.branch.id.toString() === '-1'
                  } else {
                    return item.branch ? item.branch.id.toString() === branch.id.toString() : false
                  }
                })
              }
              commit('LOAD_NOTE_SALE_2_LST', arr.reverse())
            } else {
              commit('LOAD_NOTE_SALE_2_LST', [])
            }
          } else {
            commit('LOAD_NOTE_SALE_2_LST', [])
          }
        })
      }
    },
    loadNoteSaleFilter ({ commit, rootState }, { firstDay, lastDay, branch }) {
      return new Promise(resolve => {
        if (rootState.user.user) {
          firebase.database().ref(`notesale/${rootState.user.user.uid}`).orderByChild('fechaEmision')
            .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
            if (snapshot) {
              let arr = []
              const res = snapshot.val()
              if (res) {
                arr = Object.keys(res).map(cou => {
                  res[cou].id = cou
                  return res[cou]
                })
                if (branch) {
                  arr = arr.filter(item => {
                    if (branch.id.toString() === '-1') {
                      return !item.branch || item.branch.id.toString() === '-1'
                    } else {
                      return item.branch ? item.branch.id.toString() === branch.id.toString() : false
                    }
                  })
                }
                commit('LOAD_NOTE_SALE_LST', arr.reverse())
                resolve(arr.reverse())
              } else {
                commit('LOAD_NOTE_SALE_LST', [])
                resolve([])
              }
            } else {
              commit('LOAD_NOTE_SALE_LST', [])
              resolve([])
            }
          })
        }
      })
    },
    loadNoteSaleFilter2 ({ commit, rootState }, { firstDay, lastDay, branch }) {
      if (rootState.user.user) {
        firebase.database().ref(`notesale2/${rootState.user.user.uid}`).orderByChild('fechaEmision')
          .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              if (branch) {
                arr = arr.filter(item => {
                  if (branch.id.toString() === '-1') {
                    return !item.branch || item.branch.id.toString() === '-1'
                  } else {
                    return item.branch ? item.branch.id.toString() === branch.id.toString() : false
                  }
                })
              }
              commit('LOAD_NOTE_SALE_2_LST', arr.reverse())
            } else {
              commit('LOAD_NOTE_SALE_2_LST', [])
            }
          } else {
            commit('LOAD_NOTE_SALE_2_LST', [])
          }
        })
      }
    },
    loadFacturaPurchse ({ commit, rootState }, branch) {
      if (rootState.user.user) {
        // var date = new Date()
        var firstDay = moment(new Date()).format('YYYY-MM-DD')
        var lastDay = moment(new Date()).format('YYYY-MM-DD')
        firebase.database().ref(`facturapurchase/${rootState.user.user.uid}`).orderByChild('fechaEmision')
          .startAt(firstDay).endAt(lastDay).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              if (branch) {
                arr = arr.filter(item => {
                  if (branch.id.toString() === '-1') {
                    return !item.branch || item.branch.id.toString() === '-1'
                  } else {
                    return item.branch ? item.branch.id.toString() === branch.id.toString() : false
                  }
                })
              }
              commit('LOAD_FACTURA_PURCHASE_LST', arr.reverse())
            } else {
              commit('LOAD_FACTURA_PURCHASE_LST', [])
            }
          } else {
            commit('LOAD_FACTURA_PURCHASE_LST', [])
          }
        })
      }
    },
    loadPurchaseFilter ({ commit, rootState }, { firstDay, lastDay, branch }) {
      if (rootState.user.user) {
        firebase.database().ref(`facturapurchase/${rootState.user.user.uid}`).orderByChild('fechaEmision')
          .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              if (branch) {
                arr = arr.filter(item => {
                  if (branch.id.toString() === '-1') {
                    return !item.branch || item.branch.id.toString() === '-1'
                  } else {
                    return item.branch ? item.branch.id.toString() === branch.id.toString() : false
                  }
                })
              }
              commit('LOAD_FACTURA_PURCHASE_LST', arr.reverse())
            } else {
              commit('LOAD_FACTURA_PURCHASE_LST', [])
            }
          } else {
            commit('LOAD_FACTURA_PURCHASE_LST', [])
          }
        })
      }
    },
    loadNotePurchse ({ commit, rootState }, branch) {
      if (rootState.user.user) {
        firebase.database().ref(`notepurchase/${rootState.user.user.uid}`).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              if (branch) {
                arr = arr.filter(item => {
                  if (branch.id.toString() === '-1') {
                    return !item.branch || item.branch.id.toString() === '-1'
                  } else {
                    return item.branch ? item.branch.id.toString() === branch.id.toString() : false
                  }
                })
              }
              commit('LOAD_NOTE_PURCHASE_LST', arr.reverse())
            } else {
              commit('LOAD_NOTE_PURCHASE_LST', [])
            }
          } else {
            commit('LOAD_NOTE_PURCHASE_LST', [])
          }
        })
      }
    },
  },
}
