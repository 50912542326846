import axios from 'axios'
import * as vars from '../../../vars.json'
import firebase from 'firebase/app'
import moment from 'moment'

const httpAxios = axios.create({
  params: {},
})

export const entryguides = {
  namespaced: true,
  state: {
    entryGuidesLst: [],
    entryGuidesLstProvider: [],
  },
  mutations: {
    LOAD_ENTRY_GUIDE_LST: (state, data) => {
      state.entryGuidesLst = data
    },
    LOAD_ENTRY_GUIDE_LST_PROVIDER: (state, data) => {
      state.entryGuidesLstProvider = data
    },
  },
  actions: {
    saveEntryGuide ({ commit, rootState }, [entryData, productData, update]) {
      return new Promise((resolve, reject) => {
        const dataEntrySend = {
          entryData: entryData,
          uidUser: rootState.user.user.uid,
          productData: productData,
          update: update,
        }
        httpAxios.post(vars.url + '/addNewEntryGuide', dataEntrySend).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    saveEntryGuideLst ({ commit, rootState }, entryGuideDataSendLst) {
      return new Promise((resolve, reject) => {
        httpAxios.post(vars.url + '/addNewEntryGuideLst', entryGuideDataSendLst).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    loadEntryGuides ({ commit, rootState }) {
      if (rootState.user.user) {
        // var date = new Date()
        var firstDay = moment(new Date()).format('YYYY-MM-DD')
        var lastDay = moment(new Date()).format('YYYY-MM-DD')
        firebase.database().ref(`entryguides/${rootState.user.user.uid}`).orderByChild('dateEntry')
          .startAt(firstDay).endAt(lastDay).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('LOAD_ENTRY_GUIDE_LST', arr.reverse())
            } else {
              commit('LOAD_ENTRY_GUIDE_LST', [])
            }
          } else {
            commit('LOAD_ENTRY_GUIDE_LST', [])
          }
        })
      }
    },
    loadEntryGuidesFilterDate ({ commit, rootState }, { firstDay, lastDay }) {
      if (rootState.user.user) {
        // var date = new Date()
        firebase.database().ref(`entryguides/${rootState.user.user.uid}`).orderByChild('dateEntry')
          .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('LOAD_ENTRY_GUIDE_LST', arr.reverse())
            } else {
              commit('LOAD_ENTRY_GUIDE_LST', [])
            }
          } else {
            commit('LOAD_ENTRY_GUIDE_LST', [])
          }
        })
      }
    },
    loadEntryGuidesFilterDateProvider ({ commit, rootState }, [firstDay, lastDay, idProvider]) {
      if (rootState.user.user) {
        // var date = new Date()
        firebase.database().ref(`entryguides/${rootState.user.user.uid}`).orderByChild('dateEntry')
          .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            let arrFilterProvider = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              arrFilterProvider = arr.filter(item => {
                return item.providerSelect.providerKey === idProvider
              })
              commit('LOAD_ENTRY_GUIDE_LST_PROVIDER', arrFilterProvider.reverse())
            } else {
              commit('LOAD_ENTRY_GUIDE_LST_PROVIDER', [])
            }
          } else {
            commit('LOAD_ENTRY_GUIDE_LST_PROVIDER', [])
          }
        })
      }
    },
  },
}
