// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/firestore'
import 'firebase/auth'
import VueLoading from 'vuejs-loading-plugin'
import 'bootstrap'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VuetifyMask from 'vuetify-mask'

Vue.use(AOS.init())

Vue.config.productionTip = false
Vue.use(VueLoading, { text: 'Cargando', dark: true })
Vue.use(VuetifyMask)

var firebaseConfig = {
  apiKey: 'AIzaSyBd5OWirS1VH7O2qZXw3hWuUqdJplR6qtY',
  authDomain: 'inventarix.firebaseapp.com',
  projectId: 'inventarix',
  storageBucket: 'inventarix.appspot.com',
  messagingSenderId: '718662985857',
  appId: '1:718662985857:web:2a343ac5df75fefbc60580',
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()
firebase.firestore().enablePersistence()
  .catch(function (err) {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  })

firebase.auth().onAuthStateChanged(() => {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
  }).$mount('#app')
})
