import * as vars from '@/vars.json'
import axios from 'axios'
import moment from 'moment/moment'
import firebase from 'firebase/app'
import 'firebase/database'

const httpAxios = axios.create({
  params: {},
})

export const pettycash = {
  namespaced: true,
  state: {
    pettycash: null,
    pettycashEntryLst: [],
    pettycashEgressLst: [],
    totalEntryVal: 0,
    totalEgressVal: 0,
  },
  mutations: {
    SET_PETTY_CASH: (state, data) => {
      state.pettycash = data
    },
    SET_PETTYCASHENTRY_LST: (state, data) => {
      state.pettycashEntryLst = data
    },
    SET_PETTYCASHEGRESS_LST: (state, data) => {
      state.pettycashEgressLst = data
    },
    SET_TOTAL_ENTRY: (state, data) => {
      state.totalEntryVal = data
    },
    SET_TOTAL_EGRESS: (state, data) => {
      state.totalEgressVal = data
    },
  },
  actions: {
    setBalance ({ state, rootState, commit }, balanceVal) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`pettycash/${rootState.user.user.uid}/balance`).set(balanceVal).then((res) => {
          resolve(true)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    saveNewPettyCashEntry ({ commit, rootState }, entryData) {
      return new Promise((resolve, reject) => {
        const dataAddNewPettyCahsEntry = {
          pettycashentry: entryData,
          userId: rootState.user.user.uid,
        }
        httpAxios.post(vars.url + '/addNewPettyCahsEntry', dataAddNewPettyCahsEntry).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    saveNewPettyCahsEgress ({ commit, rootState }, egressData) {
      return new Promise((resolve, reject) => {
        const dataAddNewPettyCahsEgress = {
          pettycashegress: egressData,
          userId: rootState.user.user.uid,
        }
        httpAxios.post(vars.url + '/addNewPettyCahsEgress', dataAddNewPettyCahsEgress).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    deletePettyCashEntry ({ commit, rootState }, entryData) {
      return new Promise((resolve, reject) => {
        const dataAddNewPettyCahsEntry = {
          pettycashentry: entryData,
          userId: rootState.user.user.uid,
        }
        httpAxios.post(vars.url + '/deletePettyCahsEntry', dataAddNewPettyCahsEntry).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    deletePettyCahsEgress ({ commit, rootState }, egressData) {
      return new Promise((resolve, reject) => {
        const dataAddNewPettyCahsEgress = {
          pettycashegress: egressData,
          userId: rootState.user.user.uid,
        }
        httpAxios.post(vars.url + '/deletePettyCahsEgress', dataAddNewPettyCahsEgress).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    loadEntryArr ({ commit, rootState }) {
      commit('SET_TOTAL_ENTRY', 0)
      var firstDay = moment(new Date()).format('YYYY-MM-DD')
      var lastDay = new Date().toISOString().substr(0, 10)
      let arrEntryTotal = []
      firebase.database().ref(`pettycashentry/${rootState.user.user.uid}`).orderByChild('date')
        .startAt(firstDay).endAt(lastDay).on('value', (snapshot) => {
        let sumEntry = 0
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              sumEntry = parseFloat(sumEntry) + parseFloat(res[cou].amount)
              res[cou].id = cou
              return res[cou]
            })
            arr.reverse()
            arrEntryTotal = arr
          }
        }
        commit('SET_TOTAL_ENTRY', sumEntry)
        commit('SET_PETTYCASHENTRY_LST', arrEntryTotal)
      })
    },
    loadEgressArr ({ commit, rootState }) {
      commit('SET_TOTAL_EGRESS', 0)
      var firstDay = moment(new Date()).format('YYYY-MM-DD')
      var lastDay = new Date().toISOString().substr(0, 10)
      let arrEgressTotal = []
      firebase.database().ref(`pettycashegress/${rootState.user.user.uid}`).orderByChild('date')
        .startAt(firstDay).endAt(lastDay).on('value', (snapshot) => {
        let sumEgress = 0
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              sumEgress = parseFloat(sumEgress) + parseFloat(res[cou].amount)
              res[cou].id = cou
              return res[cou]
            })
            arr.reverse()
            arrEgressTotal = arr
          }
        }
        commit('SET_TOTAL_EGRESS', sumEgress)
        commit('SET_PETTYCASHEGRESS_LST', arrEgressTotal)
      })
    },
    loadEntryArrFilterDate ({ commit, rootState }, [firstDay, lastDay]) {
      commit('SET_TOTAL_ENTRY', 0)
      let arrEntryTotal = []
      firebase.database().ref(`pettycashentry/${rootState.user.user.uid}`).orderByChild('date')
        .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
        let sumEntry = 0
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              sumEntry = parseFloat(sumEntry) + parseFloat(res[cou].amount)
              res[cou].id = cou
              return res[cou]
            })
            arr.reverse()
            arrEntryTotal = arr
          }
        }
        commit('SET_TOTAL_ENTRY', sumEntry)
        commit('SET_PETTYCASHENTRY_LST', arrEntryTotal)
      })
    },
    loadEgressArrFilterDate ({ commit, rootState }, [firstDay, lastDay]) {
      commit('SET_TOTAL_EGRESS', 0)
      let arrEgressTotal = []
      firebase.database().ref(`pettycashegress/${rootState.user.user.uid}`).orderByChild('date')
        .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
        let sumEgress = 0
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              sumEgress = parseFloat(sumEgress) + parseFloat(res[cou].amount)
              res[cou].id = cou
              return res[cou]
            })
            arr.reverse()
            arrEgressTotal = arr
          }
        }
        commit('SET_TOTAL_EGRESS', sumEgress)
        commit('SET_PETTYCASHEGRESS_LST', arrEgressTotal)
      })
    },
    loadPettyCashData ({ commit, rootState }) {
      firebase.database().ref(`pettycash/${rootState.user.user.uid}`).on('value', (snapshot) => {
        if (snapshot) {
          if (snapshot.val()) {
            const res = snapshot.val()
            if (res) {
              commit('SET_PETTY_CASH', res)
            } else {
              commit('SET_PETTY_CASH', null)
            }
          } else {
            commit('SET_PETTY_CASH', null)
          }
        } else {
          commit('SET_PETTY_CASH', null)
        }
      })
    },
  },
}
