// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const table = {
  namespaced: true,
  state: {
    openTableLst: [],
    tableSecuential: null,
  },
  mutations: {
    SET_OPEN_TABLE_LST: (state, lst) => {
      state.openTableLst = lst
    },
    LOAD_OPENTABLE_SECUENTIAL: (state, data) => {
      state.tableSecuential = data
    },
  },
  actions: {
    setOpenTable ({ rootState }, openTable) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`openTable/${rootState.user.user.uid}/`).push(openTable).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    updateOpenTable ({ rootState }, openTable) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`openTable/${rootState.user.user.uid}/${openTable.id}`).set(openTable).then((res) => {
          if (res) {
            resolve(res)
          } else {
            resolve(true)
          }
        }).catch((err) => {
          reject(err)
        })
      })
    },
    closeOpenTable ({ rootState }, openTable) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`openTable/${rootState.user.user.uid}/${openTable.id}`).remove().then((res) => {
          if (res) {
            resolve(res)
          } else {
            resolve(true)
          }
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadOpenTableLst ({ commit, rootState }) {
      commit('SET_OPEN_TABLE_LST', [])
      if (rootState.user.user) {
        firebase.database().ref(`openTable/${rootState.user.user.uid}`).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                if (res[cou].status) {
                  if (res[cou].status.toUpperCase() === 'CERRADA') {
                    firebase.database().ref(`openTable/${rootState.user.user.uid}/${res[cou].id}`).remove()
                  }
                }
                return res[cou]
              })
              commit('SET_OPEN_TABLE_LST', arr.reverse())
            } else {
              commit('SET_OPEN_TABLE_LST', [])
            }
          } else {
            commit('SET_OPEN_TABLE_LST', [])
          }
        })
      }
    },
    // metodos para la numeración del secuencial
    setActualSecuential ({ rootState }, actualSecuential) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`tableSecuential/${rootState.user.user.uid}`).set(actualSecuential).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadActualSecuential ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`tableSecuential/${rootState.user.user.uid}`).once('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              commit('LOAD_OPENTABLE_SECUENTIAL', res)
              resolve()
            } else {
              commit('LOAD_OPENTABLE_SECUENTIAL', 0)
              resolve()
            }
          } else {
            commit('LOAD_OPENTABLE_SECUENTIAL', 0)
            resolve()
          }
        })
      })
    },
  },
}
