// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'
import * as vars from '@/vars.json'
import axios from 'axios/index'

const httpAxios = axios.create({
  params: {},
})

export const transaction = {
  namespaced: true,
  state: {
    lstTransactions: [],
    lstTransactionsCombos: [],
    lstTransactionsReport: [],
  },
  mutations: {
    LOAD_TRANSACTIONS: (state, data) => {
      state.lstTransactions = data
    },
    LOAD_TRANSACTIONS_COMBOS: (state, data) => {
      state.lstTransactionsCombos = data
    },
    LOAD_TRANSACTIONS_REPORT: (state, data) => {
      state.lstTransactionsReport = data
    },
  },
  actions: {
    saveTransaction ({ rootState }, transactionData) {
      return new Promise((resolve, reject) => {
        transactionData.documentId = 'Sin valor tributario'
        firebase.database().ref(`transactions/${rootState.user.user.uid}/${transactionData.productId}`).push(transactionData).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    saveTransactionBack ({ rootState }, [transactionData, productData]) {
      return new Promise((resolve, reject) => {
        transactionData.documentId = 'Sin valor tributario'
        transactionData.userId = rootState.user.user.uid
        const obj = {
          transactionData: transactionData,
          productData: productData,
        }
        httpAxios.post(vars.url + '/setTransactionProduct', obj).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    saveTransactionBackCombo ({ rootState }, [transactionData, productData]) {
      return new Promise((resolve, reject) => {
        transactionData.documentId = 'Sin valor tributario'
        transactionData.userId = rootState.user.user.uid
        const obj = {
          transactionData: transactionData,
          productData: productData,
        }
        httpAxios.post(vars.url + '/setTransactionComboProduct', obj).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    loadTransactions ({ commit, rootState }, [productIdSelected, firstDay]) {
      firebase.database().ref(`transactions/${rootState.user.user.uid}/${productIdSelected}`)
        .orderByChild('transactionDate').startAt(firstDay).endAt(firstDay).once('value', (snapshot) => {
        if (snapshot) {
          let arr = []
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              res[cou].id = cou
              return res[cou]
            })
            commit('LOAD_TRANSACTIONS', arr)
          } else {
            commit('LOAD_TRANSACTIONS', [])
          }
        } else {
          commit('LOAD_TRANSACTIONS', [])
        }
      })
    },
    saveTransactionCombo ({ rootState }, transactionData) {
      return new Promise((resolve, reject) => {
        transactionData.documentId = 'Sin valor tributario'
        firebase.database().ref(`transactionscombos/${rootState.user.user.uid}/${transactionData.productId}`).push(transactionData).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadTransactionsCombo ({ commit, rootState }, [productIdSelected, firstDay]) {
      firebase.database().ref(`transactionscombos/${rootState.user.user.uid}/${productIdSelected}`)
        .orderByChild('transactionDate').startAt(firstDay).endAt(firstDay).once('value', (snapshot) => {
        if (snapshot) {
          let arr = []
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              res[cou].id = cou
              return res[cou]
            })
            commit('LOAD_TRANSACTIONS_COMBOS', arr)
          } else {
            commit('LOAD_TRANSACTIONS_COMBOS', [])
          }
        } else {
          commit('LOAD_TRANSACTIONS_COMBOS', [])
        }
      })
    },
    async loadTransactionsReport ({ commit, rootState }, [productIdSelected, firstDay, lastDay, type]) {
      const arrCombo = []
      await firebase.database().ref(`transactionscombos/${rootState.user.user.uid}/${productIdSelected}`)
        .orderByChild('transactionDate').startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
        if (snapshot) {
          const res = snapshot.val()
          let arr = []
          if (res) {
            arr = Object.keys(res).map(cou => {
              res[cou].id = cou
              return res[cou]
            })
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].documentId !== 'Sin valor tributario') {
                arrCombo.push(arr[i])
              }
            }
          }
        }
      })
      const arrProduct = []
      await firebase.database().ref(`transactions/${rootState.user.user.uid}/${productIdSelected}`)
        .orderByChild('transactionDate').startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
        if (snapshot) {
          const res = snapshot.val()
          let arr = []
          if (res) {
            arr = Object.keys(res).map(cou => {
              res[cou].id = cou
              return res[cou]
            })
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].documentId !== 'Sin valor tributario') {
                arrProduct.push(arr[i])
              }
            }
          }
        }
      })
      const arrComplete = arrCombo.concat(arrProduct)
      let arrReturn = []
      if (type.toString() === '001') {
        arrReturn = arrComplete
      }
      if (type.toString() === '002') {
        arrReturn = arrComplete.filter(item => {
          return item.transactionType.toString().toUpperCase() === 'SALIDA'
        })
      }
      if (type.toString() === '003') {
        arrReturn = arrComplete.filter(item => {
          return item.transactionType.toString().toUpperCase() === 'INGRESO'
        })
      }
      commit('LOAD_TRANSACTIONS_REPORT', arrReturn)
    },
  },
}
