// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'
import * as vars from '../../../vars'
import axios from 'axios'
import moment from 'moment/moment'

const httpAxios = axios.create({
  params: {},
})

export const receipt = {
  namespaced: true,
  state: {
    secuenciaRecibo: '0',
    lstRecibos: [],
    lstRecibosProveedores: null,
  },
  mutations: {
    SET_SECUENCIAL: (state, data) => {
      state.secuenciaRecibo = data
    },
    SET_LST_RECIBOS: (state, data) => {
      state.lstRecibos = data
    },
    SET_LST_RECIBOS_PROVEEDORES: (state, data) => {
      state.lstRecibosProveedores = data
    },
  },
  actions: {
    setSecuencial ({ commit, rootState }, secuencial) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`secuencialRecibo/${rootState.user.user.uid}/secuencial`).set(secuencial).then((res) => {
          commit('SET_SECUENCIAL', secuencial)
          resolve(secuencial)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadSecuencial ({ commit, rootState }) {
      firebase.database().ref(`secuencialRecibo/${rootState.user.user.uid}/secuencial`).on('value', (snapshot) => {
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            commit('SET_SECUENCIAL', res)
          } else {
            commit('SET_SECUENCIAL', '0')
          }
        } else {
          commit('SET_SECUENCIAL', '0')
        }
      })
    },
    saveReceipt ({ state, rootState, commit }, receiptData) {
      return new Promise((resolve, reject) => {
        const receiptDataSend = {
          receiptData: receiptData,
          userUid: rootState.user.user.uid,
        }
        httpAxios.post(vars.url + '/saveReceiptPurchase', receiptDataSend).then(res => {
          resolve(res)
        }, error => {
          reject(error)
        })
      })
    },
    anulReceipt ({ state, rootState, commit }, receiptData) {
      return new Promise((resolve, reject) => {
        const receiptDataSend = {
          receiptData: receiptData,
          userUid: rootState.user.user.uid,
        }
        httpAxios.post(vars.url + '/anulReceiptPurchase', receiptDataSend).then(res => {
          resolve(res)
        }, error => {
          reject(error)
        })
      })
    },
    loadReceipts ({ commit, rootState }) {
      var firstDay = moment(new Date()).format('YYYY-MM-DD')
      var lastDay = moment(new Date()).format('YYYY-MM-DD')
      if (rootState.user.user) {
        firebase.database().ref(`recibos/${rootState.user.user.uid}`).orderByChild('fechaEmision')
          .startAt(firstDay).endAt(lastDay).on('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('SET_LST_RECIBOS', arr.reverse())
            } else {
              commit('SET_LST_RECIBOS', arr)
            }
          } else {
            commit('SET_LST_RECIBOS', arr)
          }
        })
      }
    },
    loadReceiptsFilter ({ commit, rootState }, { firstDay, lastDay }) {
      if (rootState.user.user) {
        firebase.database().ref(`recibos/${rootState.user.user.uid}`).orderByChild('fechaEmision')
          .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('SET_LST_RECIBOS', arr.reverse())
            } else {
              commit('SET_LST_RECIBOS', arr)
            }
          } else {
            commit('SET_LST_RECIBOS', arr)
          }
        })
      }
    },
    loadReceiptsProviderFilter ({ commit, rootState }, [firstDay, lastDay, idProvider]) {
      if (rootState.user.user) {
        firebase.database().ref(`recibos/${rootState.user.user.uid}`).orderByChild('fechaEmision')
          .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
          let arr = []
          let arrReceiptsValidData = []
          const arrReceiptsResponse = {
            receipts: [],
            lstReceiptsProductos: [],
          }
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              arrReceiptsValidData = arr.filter(item => {
                return (item.estado ? item.estado !== 'ANULADO' : true) && item.proveedor.providerKey === idProvider
              })
              for (let i = 0; i < arrReceiptsValidData.length; i++) {
                for (let j = 0; j < arrReceiptsValidData[i].listaProductos.length; j++) {
                  const newDataView = {
                    fechaEmision: arrReceiptsValidData[i].fechaEmision,
                    secuencial: arrReceiptsValidData[i].secuencial,
                    proveedor: arrReceiptsValidData[i].proveedor,
                    cantidad: arrReceiptsValidData[i].listaProductos[j].cantidad,
                    name: arrReceiptsValidData[i].listaProductos[j].producto.description,
                    valorUnitario: arrReceiptsValidData[i].listaProductos[j].valorUnitario,
                    total: arrReceiptsValidData[i].listaProductos[j].total,
                    codeProduct: arrReceiptsValidData[i].listaProductos[j].producto.codArticle,
                  }
                  arrReceiptsResponse.lstReceiptsProductos.push(newDataView)
                }
                const dataReceipt = {
                  transporte: arrReceiptsValidData[i].transporte,
                  usuario: arrReceiptsValidData[i].usuario,
                  secuencial: arrReceiptsValidData[i].secuencial,
                  total: arrReceiptsValidData[i].total,
                  proveedor: arrReceiptsValidData[i].proveedor.completeData,
                  fechaEmision: arrReceiptsValidData[i].fechaEmision,
                }
                arrReceiptsResponse.receipts.push(dataReceipt)
              }
              commit('SET_LST_RECIBOS_PROVEEDORES', arrReceiptsResponse)
            } else {
              commit('SET_LST_RECIBOS_PROVEEDORES', null)
            }
          } else {
            commit('SET_LST_RECIBOS_PROVEEDORES', null)
          }
        })
      }
    },
  },
}
