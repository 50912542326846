<template>
  <div>
    <offline @detected-condition="handleConnectivityChange" />
    <v-snackbar
      v-model="snackbar.show"
      :timeout="360000"
      top=""
      right=""
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn
        dark=""
        icon=""
        @click.native="snackbar.show = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <router-view />
  </div>
</template>

<script>
  import offline from 'v-offline'
  export default {
    name: 'App',
    components: {
      offline,
    },
    data: () => ({
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
    }),
    methods: {
      handleConnectivityChange (status) {
        if (status) {
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Conectado a la red',
          }
        } else {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Por favor verifica tu conexión a Internet. Y recarga la página.',
          }
        }
      },
    },
  }
</script>
