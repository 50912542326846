// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const agenda = {
  namespaced: true,
  state: {
    lstAgenda: [],
  },
  mutations: {
    SET_LST_AGENDA: (state, data) => {
      state.lstAgenda = data
    },
  },
  actions: {
    loadDataAgenda ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`schedule/${rootState.user.user.uid}`).on('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('SET_LST_AGENDA', arr)
              resolve(arr)
            } else {
              commit('SET_LST_AGENDA', [])
              resolve([])
            }
          } else {
            commit('SET_LST_AGENDA', [])
            resolve([])
          }
        })
      })
    },
    loadDataAgendaFilterDate ({ commit, rootState }, { firstDay, lastDay }) {
      return new Promise((resolve, reject) => {
        if (firstDay.toString() === lastDay.toString()) {
          firebase.database().ref(`schedule/${rootState.user.user.uid}`).orderByChild('day')
            .equalTo(firstDay).once('value', (snapshot) => {
            let arr = []
            if (snapshot) {
              const res = snapshot.val()
              if (res) {
                arr = Object.keys(res).map(cou => {
                  res[cou].id = cou
                  return res[cou]
                })
                arr.sort(function (a, b) {
                  var c = new Date(a.start)
                  var d = new Date(b.start)
                  return c - d
                })
                resolve(arr)
              } else {
                resolve([])
              }
            } else {
              resolve([])
            }
          })
        } else {
          firebase.database().ref(`schedule/${rootState.user.user.uid}`).orderByChild('start')
            .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
            let arr = []
            if (snapshot) {
              const res = snapshot.val()
              if (res) {
                arr = Object.keys(res).map(cou => {
                  res[cou].id = cou
                  return res[cou]
                })
                arr.sort(function (a, b) {
                  var c = new Date(a.start)
                  var d = new Date(b.start)
                  return c - d
                })
                resolve(arr)
              } else {
                resolve([])
              }
            } else {
              resolve([])
            }
          })
        }
      })
    },
  },
}
