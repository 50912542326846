// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const proforma = {
  namespaced: true,
  state: {
    proformaLst: [],
  },
  mutations: {
    LOAD_PROFORMA_LST: (state, data) => {
      state.proformaLst = data
    },
  },
  actions: {
    setProforma ({ state, rootState }, [proformaData, secproforma]) {
      return new Promise((resolve, reject) => {
        secproforma = (parseInt(secproforma) + 1).toString()
        firebase.database().ref(`users/${rootState.user.user.uid}/secuenciales/proforma`).set(secproforma)
        firebase.database().ref(`proforma/${rootState.user.user.uid}`).push(proformaData).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadProforma ({ commit, rootState }) {
      if (rootState.user.user) {
        firebase.database().ref(`proforma/${rootState.user.user.uid}`).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('LOAD_PROFORMA_LST', arr.reverse())
            } else {
              commit('LOAD_PROFORMA_LST', [])
            }
          } else {
            commit('LOAD_PROFORMA_LST', [])
          }
        })
      }
    },
    deleteProforma ({ state, rootState }, proforma) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`proforma/${rootState.user.user.uid}/${proforma}`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
