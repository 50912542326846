// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const ivaretenciones = {
  namespaced: true,
  state: {
    iva: null,
    retencionesLst: [],
    retencionesIrLst: [],
    retencionesIvaLst: [],
    retencionesComprasLst: [],
  },
  mutations: {
    SET_IVA_VAL: (state, val) => {
      state.iva = val
    },
    SET_RETENCIONES_LST: (state, data) => {
      state.retencionesLst = data
    },
    SET_RETENCIONES_IR_LST: (state, data) => {
      state.retencionesIrLst = data
    },
    SET_RETENCIONES_IVA_LST: (state, data) => {
      state.retencionesIvaLst = data
    },
    SET_RETENCIONES_COMPRAS_LST: (state, data) => {
      state.retencionesComprasLst = data
    },
  },
  actions: {
    guardarValorIva ({ commit, rootState }, valIva) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`iva/${rootState.user.user.uid}`).set(
          valIva,
        ).then(res => {
          resolve(true)
        }).catch(error => {
          reject(error)
        })
      })
    },
    loadFilterData ({ commit, rootState }, stringFilter) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`retenciones/${rootState.user.user.uid}`).orderByChild('filterdata').equalTo(stringFilter).once('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              resolve(arr)
            } else {
              resolve([])
            }
          } else {
            resolve([])
          }
        })
      })
    },
    guardarRetenciones ({ commit, rootState }, [retencionId, retencion]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`retenciones/${rootState.user.user.uid}/${retencionId}`).set(retencion).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    cargarValorIva ({ commit, rootState }) {
      if (rootState.user.user) {
        firebase.database().ref(`iva/${rootState.user.user.uid}`).on('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              commit('SET_IVA_VAL', res)
            } else {
              commit('SET_IVA_VAL', '0')
            }
          } else {
            commit('SET_IVA_VAL', '0')
          }
        })
      }
    },
    cargarListaRetenciones ({ commit, rootState }) {
      if (rootState.user.user) {
        firebase.database().ref(`retenciones/${rootState.user.user.uid}`).on('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('SET_RETENCIONES_LST', arr)
            } else {
              commit('SET_RETENCIONES_LST', [])
            }
          } else {
            commit('SET_RETENCIONES_LST', [])
          }
        })
      }
    },
    cargarRetencionesIr ({ commit, rootState }) {
      if (rootState.user.user) {
        firebase.database().ref(`retenciones/${rootState.user.user.uid}/`).orderByChild('tipo/codigo').equalTo('002').on('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('SET_RETENCIONES_IR_LST', arr)
            } else {
              commit('SET_RETENCIONES_IR_LST', [])
            }
          } else {
            commit('SET_RETENCIONES_IR_LST', [])
          }
        })
      }
    },
    cargarRetencionesIva ({ commit, rootState }) {
      if (rootState.user.user) {
        firebase.database().ref(`retenciones/${rootState.user.user.uid}/`).orderByChild('tipo/codigo').equalTo('001').on('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('SET_RETENCIONES_IVA_LST', arr)
            } else {
              commit('SET_RETENCIONES_IVA_LST', [])
            }
          } else {
            commit('SET_RETENCIONES_IVA_LST', [])
          }
        })
      }
    },
  },
}
