import axios from 'axios'
import * as vars from '../../../vars.json'
import firebase from 'firebase'

const httpAxios = axios.create({
  params: {},
})

export const advance = {
  namespaced: true,
  state: {
    advancesLst: [],
    advancesMovementsLst: [],
    advancesProviderLst: [],
  },
  mutations: {
    LOAD_ADVACES: (state, data) => {
      state.advancesLst = data
    },
    LOAD_ADVACES_MOVEMENTS: (state, data) => {
      state.advancesMovementsLst = data
    },
    LOAD_ADVACES_PROVIDER: (state, data) => {
      state.advancesProviderLst = data
    },
  },
  actions: {
    saveAdvance ({ state, rootState, commit }, advanceData) {
      return new Promise((resolve, reject) => {
        const advanceDataSend = {
          advanceData: advanceData,
          userUid: rootState.user.user.uid,
        }
        httpAxios.post(vars.url + '/addNewAdvance', advanceDataSend).then(res => {
          resolve(res)
        }, error => {
          reject(error)
        })
      })
    },
    loadAdvances ({ commit, rootState }) {
      if (rootState.user.user) {
        firebase.database().ref(`advance/${rootState.user.user.uid}`).on('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('LOAD_ADVACES', arr.reverse())
            } else {
              commit('LOAD_ADVACES', arr)
            }
          } else {
            commit('LOAD_ADVACES', arr)
          }
        })
      }
    },
    loadAdvancesProvider ({ commit, rootState }, [firstDay, lastDay, providerId]) {
      if (rootState.user.user) {
        firebase.database().ref(`advance/${rootState.user.user.uid}`).orderByChild('createDate')
          .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
          let arr = []
          let arrResponse = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              arrResponse = arr.filter(item => {
                return item.provider.providerKey === providerId
              })
              commit('LOAD_ADVACES_PROVIDER', arrResponse.reverse())
            } else {
              commit('LOAD_ADVACES_PROVIDER', arr)
            }
          } else {
            commit('LOAD_ADVACES_PROVIDER', arr)
          }
        })
      }
    },
    deleteAdvance ({ state, rootState }, advanceId) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`advance/${rootState.user.user.uid}/${advanceId}`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    addNewAdvanceMovement ({ state, rootState, commit }, [advanceData, movementData]) {
      return new Promise((resolve, reject) => {
        const advanceDataMovementSend = {
          advanceData: advanceData,
          userUid: rootState.user.user.uid,
          movementData: movementData,
        }
        httpAxios.post(vars.url + '/addNewAdvanceMovement', advanceDataMovementSend).then(res => {
          resolve(res)
        }, error => {
          reject(error)
        })
      })
    },
    loadAdvancesMovements ({ commit, rootState }, advanceId) {
      if (advanceId) {
        if (rootState.user.user) {
          firebase.database().ref(`advance/${rootState.user.user.uid}/${advanceId}/movements`).once('value', (snapshot) => {
            let arr = []
            if (snapshot) {
              const res = snapshot.val()
              if (res) {
                arr = Object.keys(res).map(cou => {
                  res[cou].id = cou
                  return res[cou]
                })
                commit('LOAD_ADVACES_MOVEMENTS', arr)
              } else {
                commit('LOAD_ADVACES_MOVEMENTS', [])
              }
            } else {
              commit('LOAD_ADVACES_MOVEMENTS', [])
            }
          })
        }
      } else {
        commit('LOAD_ADVACES_MOVEMENTS', [])
      }
    },
    loadEndAdvance ({ commit, rootState }, advanceId) {
      return new Promise(resolve => {
        if (rootState.user.user) {
          firebase.database().ref(`advance/${rootState.user.user.uid}/${advanceId}`).once('value', (snapshot) => {
            if (snapshot) {
              const res = snapshot.val()
              if (res) {
                resolve(res)
              } else {
                resolve(false)
              }
            } else {
              resolve(false)
            }
          }).catch(e => {
            resolve(false)
          })
        } else {
          resolve(false)
        }
      })
    },
    loadEndAdvanceProvider ({ commit, rootState }, rucProvider) {
      return new Promise(resolve => {
        let arr = []
        if (rootState.user.user) {
          firebase.database().ref(`advance/${rootState.user.user.uid}`).orderByChild('provider/ruc')
            .equalTo(rucProvider).once('value', (snapshot) => {
            if (snapshot) {
              const res = snapshot.val()
              if (res) {
                arr = Object.keys(res).map(cou => {
                  res[cou].id = cou
                  return res[cou]
                })
                const arrFilter = arr.filter(item => {
                  return item.status === 'PENDIENTE'
                })
                resolve(arrFilter)
              } else {
                resolve(false)
              }
            } else {
              resolve(false)
            }
          }).catch(e => {
            resolve(false)
          })
        } else {
          resolve(false)
        }
      })
    },
  },
}
