// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'
import * as vars from '@/vars.json'
import axios from 'axios/index'

const httpAxios = axios.create({
  params: {},
})

export const user = {
  namespaced: true,
  state: {
    user: null,
    userLst: [],
    selectedUserUid: null,
  },
  mutations: {
    SET_USER: (state, account) => {
      state.user = account
    },
    SET_USER_LST: (state, account) => {
      state.userLst = account
    },
    SET_SELECTED_USER: (state, account) => {
      state.selectedUserUid = account
    },
  },
  actions: {
    setUserData ({ commit }, userData) {
      commit('SET_USER', userData)
    },
    setSelectedUserUid ({ commit }, uidUser) {
      commit('SET_SELECTED_USER', uidUser)
    },
    saveLastLog ({ state, rootState }, [lastLogData, userId]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`lastLog/${userId}/`).set(lastLogData).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    addNewUser ({ rootState }, userData) {
      return new Promise((resolve, reject) => {
        userData.userUidMain = rootState.user.user.uid
        httpAxios.post(vars.url + '/addNewUser', userData).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    loadUsrLst ({ commit, rootState }) {
      if (rootState.user.user) {
        firebase.database().ref(`users/${rootState.user.user.uid}/userslst`).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('SET_USER_LST', arr.reverse())
            } else {
              commit('SET_USER_LST', [])
            }
          } else {
            commit('SET_USER_LST', [])
          }
        })
      }
    },
  },
}
