import Vue from 'vue'
import Vuex from 'vuex'
import { user } from './store/modules/user'
import { product } from './store/modules/product'
import { profile } from './store/modules/profile'
import { provider } from './store/modules/provider'
import { client } from './store/modules/client'
import { ivaretenciones } from './store/modules/ivaretenciones'
import { proforma } from './store/modules/proforma'
import { factura } from './store/modules/factura'
import { transaction } from './store/modules/transaction'
import { suscriptionPlan } from './store/modules/suscriptionPlan'
import { financial } from './store/modules/financial'
import { facturacion } from './store/modules/facturacion'
import { notifications } from './store/modules/notifications'
import { table } from './store/modules/table'
import { entryguides } from './store/modules/entryguides'
import { agenda } from './store/modules/agenda'
import { advance } from './store/modules/advance'
import { pettycash } from './store/modules/pettycash'
import { receipt } from './store/modules/receipt'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    product,
    profile,
    provider,
    client,
    ivaretenciones,
    proforma,
    factura,
    transaction,
    suscriptionPlan,
    financial,
    facturacion,
    notifications,
    table,
    entryguides,
    agenda,
    advance,
    pettycash,
    receipt,
  },
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: require('@/assets/inventory-back-1.png'),
    drawer: null,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
  },
  actions: {
    getCurrentDay () {
      const today = new Date()
      const dd = String(today.getDate()).toString().padStart(2, '0')
      const mm = String(today.getMonth() + 1).toString().padStart(2, '0')
      const yyyy = today.getFullYear()

      return yyyy + '-' + mm + '-' + dd
    },
    getCurrentTime () {
      const today = new Date()
      const time = today.getHours() + ':' + today.getMinutes()
      return time
    },
    getCurrentTimeInterval ({ state }, interval) {
      const today = new Date()
      today.setHours(today.getHours() + interval)
      const time = today.getHours() + ':' + today.getMinutes()
      return time
    },
  },
})
