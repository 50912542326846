// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'
import router from '../../../router'
import store from '../../../store'

export const suscriptionPlan = {
  namespaced: true,
  state: {
    plan: null,
    suscription: null,
    initBan: true,
  },
  mutations: {
    SET_PLAN_DATA (state, data) {
      state.plan = data
    },
    SET_SUSCRIPTION_DATA (state, data) {
      state.suscription = data
    },
    SET_SUSCRIPTION_BAN (state, data) {
      state.initBan = data
    },
  },
  actions: {
    loadPlanSuscription ({ commit, rootState }) {
      if (rootState.user.user) {
        firebase.database().ref(`users/${rootState.user.user.uid}/accounts`).once('value', (snapAccounts) => {
          const resAccUser = snapAccounts.val()
          // const selectAccount = resAccUser.filter(item => {
          //   return item.name === rootState.user.user.selectAccount
          // })
          firebase.database().ref(`plans/personal/${resAccUser[0].plan}`).on('value', (snapshot) => {
            const res = snapshot.val()
            commit('SET_PLAN_DATA', res)
          })
          firebase.database().ref(`suscriptions/${resAccUser[0].suscription}`).on('value', (snapshot) => {
            const res = snapshot.val()
            const suscription = {
              days: res,
              created: resAccUser[0].suscriptionDate,
            }
            const dateInit = new Date(suscription.created)
            const currentDate = new Date()

            const differenceTime = currentDate.getTime() - dateInit.getTime()
            const differenceDays = differenceTime / (1000 * 3600 * 24)
            const restDays = parseInt(suscription.days) - parseInt(differenceDays)
            if (restDays <= 5) {
              const data = {
                descripcion: 'Su suscripción expirará en ' + restDays + ' días. Por favor comuníquese con nosotros para verificar su cuenta.',
              }
              store.dispatch('notifications/setNotificationVal', data)
            }
            if (differenceDays > suscription.days || !rootState.profile.profileLoaded.status) {
              commit('SET_SUSCRIPTION_BAN', false)
              router.push('/suscription')
            }
            commit('SET_SUSCRIPTION_DATA', suscription)
          })
        })
      }
    },
  },
}
