import firebase from 'firebase/app'
import 'firebase/database'

export const profile = {
  namespaced: true,
  state: {
    profileLoaded: null,
    lstProfiles: [],
    profileLoadedFirst: null,
    branchsProfileFilter: [],
    branchsProfile: [],
    profileType: null,
    branchSelected: null,
    branchSelectedFilter: null,
    branchChangedData: null,
  },
  mutations: {
    LOAD_PROFILE: (state, data) => {
      state.profileLoaded = data
    },
    LOAD_PROFILE_FIRST: (state, data) => {
      state.profileLoadedFirst = data
    },
    LOAD_BRANCHS_PROFILE: (state, data) => {
      state.branchsProfile = data
    },
    LOAD_BRANCHS_PROFILE_FILTER: (state, data) => {
      state.branchsProfileFilter = data
    },
    SET_SELECTED_BRANCH: (state, data) => {
      state.branchSelected = data
    },
    SET_SELECTED_BRANCH_FILTER: (state, data) => {
      state.branchSelectedFilter = data
    },
    SET_PROFILE_TYPE: (state, data) => {
      state.profileType = data
    },
    SET_DATA_RPOFILE: (state) => {
      state.profileLoaded.rucEstablecimieto = ''
      state.profileLoaded.razonSocial = ''
    },
    LOAD_PROFILES_BASE: (state, data) => {
      state.lstProfiles = data
    },
  },
  actions: {
    setBranchChangedData ({ commit }, data) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`users/${data.userUid}/branchs/${parseInt(data.branchIndex) - 2}`).on('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              commit('SET_SELECTED_BRANCH', res)
              commit('SET_SELECTED_BRANCH_FILTER', res)
              localStorage.setItem('selectedBranchLocal', JSON.stringify(res))
              resolve(res)
            } else {
              resolve(null)
            }
          }
        })
      })
    },
    setAcceptIva ({ state, rootState }, acceptIva) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`users/${rootState.user.user.uid}/acceptNewIva`).set(acceptIva).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    setProfileType ({ commit }, profileType) {
      commit('SET_PROFILE_TYPE', profileType)
    },
    getLstProfiles ({ commit }) {
      return new Promise((resolve, reject) => {
        firebase.database().ref('users').orderByChild('rucEstablecimieto').on('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              const arrSend = []
              for (let i = 0; i < arr.length; i++) {
                if (arr[i].rucEstablecimieto) {
                  arrSend.push(arr[i])
                }
              }
              commit('LOAD_PROFILES_BASE', arrSend)
            } else {
              commit('LOAD_PROFILES_BASE', [])
            }
          } else {
            commit('LOAD_PROFILES_BASE', [])
          }
        })
      })
    },
    setDataProfile ({ commit }) {
      commit('SET_DATA_RPOFILE')
    },
    setDataBranchSelected ({ commit }, data) {
      commit('SET_SELECTED_BRANCH', data)
    },
    setDataBranchSelectedFilter ({ commit }, data) {
      commit('SET_SELECTED_BRANCH_FILTER', data)
    },
    setProfile ({ state, rootState }, profileData) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`users/${rootState.user.user.uid}`).set(profileData).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadProfile ({ commit }, userUid) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`users/${userUid}`).on('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              commit('LOAD_PROFILE', res)
              resolve(res)
            } else {
              resolve(null)
            }
          }
        })
      })
    },
    loadProfileFirst ({ commit }, userUid) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`users/${userUid}`).on('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              commit('LOAD_PROFILE_FIRST', res)
              if (res.branchs) {
                const branchsAddFilter = [
                  {
                    id: -2,
                    name: 'Todas',
                    secuenciales: null,
                  },
                  {
                    id: -1,
                    name: 'Matriz',
                    secuenciales: null,
                  },
                ]
                const branchsAdd = [
                  {
                    id: -1,
                    name: 'Matriz',
                    secuenciales: null,
                  },
                ]
                const selectedBranchLocal = localStorage.getItem('selectedBranchLocal')
                if (selectedBranchLocal) {
                  commit('SET_SELECTED_BRANCH', JSON.parse(selectedBranchLocal))
                } else {
                  commit('SET_SELECTED_BRANCH',
                    {
                      id: -1,
                      name: 'Matriz',
                      secuenciales: null,
                    },
                  )
                }
                branchsAdd.push(...res.branchs)
                branchsAddFilter.push(...res.branchs)
                commit('LOAD_BRANCHS_PROFILE_FILTER', branchsAddFilter)
                commit('LOAD_BRANCHS_PROFILE', branchsAdd)
              } else {
                commit('LOAD_BRANCHS_PROFILE_FILTER', [])
                commit('LOAD_BRANCHS_PROFILE', [])
              }
              resolve(res)
            } else {
              resolve(null)
            }
          }
        })
      })
    },
  },
}
