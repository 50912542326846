// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const provider = {
  namespaced: true,
  state: {
    lstProviders: [],
    lstProvidersExternos: [],
  },
  mutations: {
    LOAD_PROVIDERS: (state, data) => {
      state.lstProviders = data
    },
    LOAD_PROVIDERS_EXTERNOS: (state, data) => {
      state.lstProvidersExternos = data
    },
  },
  actions: {
    setProvider ({ state, rootState }, [providerId, providerData]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`providers/${rootState.user.user.uid}/${providerId}`).set(providerData).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    setProviderExterno ({ state, rootState }, [providerId, providerData]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`providersexternal/${rootState.user.user.uid}/${providerId}`).set(providerData).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadProvidersExternos ({ commit, rootState }) {
      if (rootState.user.user) {
        firebase.database().ref(`providersexternal/${rootState.user.user.uid}`).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('LOAD_PROVIDERS_EXTERNOS', arr)
            } else {
              commit('LOAD_PROVIDERS_EXTERNOS', [])
            }
          } else {
            commit('LOAD_PROVIDERS_EXTERNOS', [])
          }
        })
      }
    },
    loadProviders ({ commit, rootState }) {
      if (rootState.user.user) {
        firebase.database().ref(`providers/${rootState.user.user.uid}`).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('LOAD_PROVIDERS', arr)
            } else {
              commit('LOAD_PROVIDERS', [])
            }
          } else {
            commit('LOAD_PROVIDERS', [])
          }
        })
      }
    },
    deleteProvider ({ state, rootState }, providerId) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`providers/${rootState.user.user.uid}/${providerId}`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deleteProviderExterno ({ state, rootState }, providerId) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`providersexternal/${rootState.user.user.uid}/${providerId}`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    loadProviderByGuide ({ commit, rootState }, [guideFrom, guideUntil, key]) {
      return new Promise(resolve => {
        firebase.database().ref(`providers/${rootState.user.user.uid}`).once('value', async (snapshot) => {
          let arr = []
          let arrFilter = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
            }
          }
          arrFilter = arr.filter(item => {
            return ((parseFloat(guideFrom) >= parseFloat(item.guideFrom) && parseFloat(guideUntil) <= parseFloat(item.guideUntil)) ||
              (parseFloat(guideUntil) >= parseFloat(item.guideFrom) && parseFloat(guideUntil) <= parseFloat(item.guideUntil)))
          })
          if (arrFilter.length === 0) {
            await firebase.database().ref(`providersexternal/${rootState.user.user.uid}`).once('value', (snapshot) => {
              if (snapshot) {
                const res = snapshot.val()
                if (res) {
                  arr = Object.keys(res).map(cou => {
                    res[cou].id = cou
                    return res[cou]
                  })
                }
              }
              arrFilter = arr.filter(item => {
                return ((parseFloat(guideFrom) >= parseFloat(item.guideFrom) && parseFloat(guideUntil) <= parseFloat(item.guideUntil)) ||
                  (parseFloat(guideUntil) >= parseFloat(item.guideFrom) && parseFloat(guideUntil) <= parseFloat(item.guideUntil)))
              })
            })
          }
          resolve(arrFilter)
        })
      })
    },
    loadProviderByGuideImport ({ commit, rootState }, guideSelect) {
      return new Promise(resolve => {
        firebase.database().ref(`providers/${rootState.user.user.uid}`).once('value', async (snapshot) => {
          let arr = []
          let arrFilter = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
            }
          }
          arrFilter = arr.filter(item => {
            return ((parseFloat(guideSelect) >= parseFloat(item.guideFrom) && parseFloat(guideSelect) <= parseFloat(item.guideUntil)))
          })
          if (arrFilter.length === 0) {
            await firebase.database().ref(`providersexternal/${rootState.user.user.uid}`).once('value', (snapshot) => {
              if (snapshot) {
                const res = snapshot.val()
                if (res) {
                  arr = Object.keys(res).map(cou => {
                    res[cou].id = cou
                    return res[cou]
                  })
                }
              }
              arrFilter = arr.filter(item => {
                return ((parseFloat(guideSelect) >= parseFloat(item.guideFrom) && parseFloat(guideSelect) <= parseFloat(item.guideUntil)))
              })
            })
          }
          resolve(arrFilter)
        })
      })
    },
  },
}
