import Vue from 'vue'
import Router from 'vue-router'
// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(Router)

// export default new Router({
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'landing',
      component: () => import('@/views/landingPage/LandingPage'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/Login'),
    },
    {
      path: '/suscription',
      name: 'suscription',
      meta: { requiresAuth: true },
      component: () => import('@/views/suscription/suscription'),
    },
    {
      name: 'TermsUse',
      path: '/termsUse',
      component: () => import('@/views/login/TermsUse'),
    },
    {
      path: '/dashboard',
      meta: { requiresAuth: true },
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '/dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'Perfil de Empresa',
          path: '/pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Usuarios y Perfiles',
          path: '/pages/usersprofiles',
          component: () => import('@/views/dashboard/pages/Users'),
        },
        {
          name: 'Certificación Electrónica',
          path: '/pages/certification',
          component: () => import('@/views/dashboard/pages/ElectronicCertification'),
        },
        // Upgrade
        {
          name: 'Upgrade',
          path: '/upgrade',
          component: () => import('@/views/dashboard/Upgrade'),
        },
        {
          name: 'Ingreso de Guías',
          path: '/pages/entryguides',
          component: () => import('@/views/dashboard/pages/EntryGuides'),
        },
        {
          name: 'Ingreso de Anticipos',
          path: '/pages/advance',
          component: () => import('@/views/dashboard/pages/Advance'),
        },
        {
          name: 'Agenda de Citas',
          path: '/pages/scheduleappointments',
          component: () => import('@/views/dashboard/pages/Schedule'),
        },
        {
          name: 'Productos - Inventario',
          path: '/pages/inventorydata/:id?',
          component: () => import('@/views/dashboard/pages/InventoryData'),
        },
        {
          name: 'Menú - Combos',
          path: '/pages/menucombo/:id?',
          component: () => import('@/views/dashboard/pages/MenuCombo'),
        },
        {
          name: 'Facturación',
          path: '/pages/billing',
          component: () => import('@/views/dashboard/pages/Billing'),
        },
        {
          name: 'Proveedores',
          path: '/pages/proveedores',
          component: () => import('@/views/dashboard/pages/ProveedorData'),
        },
        {
          name: 'Proveedores Internos Externos',
          path: '/pages/proveedores-internal-external',
          component: () => import('@/views/dashboard/pages/ProviderInternalExternal'),
        },
        {
          name: 'Proformas',
          path: '/pages/proforma',
          component: () => import('@/views/dashboard/pages/Proforma'),
        },
        {
          name: 'Caja Chica',
          path: '/pages/pettycash',
          component: () => import('@/views/dashboard/pages/PettyCash'),
        },
        {
          name: 'Clientes',
          path: '/pages/clients',
          component: () => import('@/views/dashboard/pages/Clients'),
        },
        {
          name: 'Notas Venta - Compra',
          path: '/pages/notes',
          component: () => import('@/views/dashboard/pages/Notes'),
        },
        {
          name: 'Recibos',
          path: '/pages/receipts',
          component: () => import('@/views/dashboard/pages/Receipts'),
        },
        {
          name: 'Estadístico',
          path: '/pages/statisticsView',
          component: () => import('@/views/dashboard/pages/IncomeExpenses'),
        },
        {
          name: 'IVA y Retenciones',
          path: '/pages/ivaretenciones',
          component: () => import('@/views/dashboard/pages/IvaRetenciones'),
        },
        {
          name: 'Herramientas',
          path: '/pages/tools',
          component: () => import('@/views/dashboard/pages/Tools'),
        },
        {
          name: 'Mesas Abiertas',
          path: '/pages/opentable',
          component: () => import('@/views/dashboard/pages/OpenTable'),
          props: true,
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (requiresAuth && !currentUser) {
    next('/login')
  } else if (requiresAuth && currentUser) {
    next()
  } else if (to.name === 'login' && currentUser) {
    next('/pages/billing')
  } else if (to.name === 'landing' && currentUser) {
    next('/pages/billing')
  } else {
    next()
  }
})

export default router
