export function logout () {
  localStorage.clear()
}

export function randomString (len, bits) {
  bits = bits || 36
  let outStr = ''
  let newStr = null
  while (outStr.length < len) {
    newStr = Math.random().toString(bits).slice(2)
    outStr += newStr.slice(0, Math.min(newStr.length, (len - outStr.length)))
  }
  return outStr.toUpperCase()
}

export function groupBy (list, keyGetter) {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}
