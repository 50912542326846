// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'
import moment from 'moment'
// const moment = require('moment')

export const financial = {
  namespaced: true,
  state: {
    ingresos: [],
    egresos: [],
    totIngresos: 0,
    totEgresos: 0,
    appointmentToCharge: null,
    receiptNumeration: null,
  },
  mutations: {
    SET_TOTAL_INGRESO: (state, data) => {
      state.totIngresos = data
    },
    SET_TOTAL_EGRESO: (state, data) => {
      state.totEgresos = data
    },
    SET_INGRESOS_LST: (state, payload) => {
      state.ingresos = payload
    },
    SET_EGRESOS_LST: (state, payload) => {
      state.egresos = payload
    },
  },
  actions: {
    loadEntryArr ({ commit, rootState }, dataUser) {
      // var date = new Date()
      commit('SET_TOTAL_INGRESO', 0)
      var firstDay = moment(new Date()).format('YYYY-MM-DD')
      let arrEntryTotal = []
      let arrEntry1 = []
      let arrEntry2 = []
      let sumTotalEntry = 0
      firebase.database().ref(`financial/${dataUser.idUser}/entry`).orderByChild('date')
        .startAt(firstDay).endAt(firstDay).on('value', (snapshot) => {
        sumTotalEntry = 0
        let sumEntry = 0
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              // sumEntry = parseFloat(sumEntry) + parseFloat(res[cou].amount)
              res[cou].id = cou
              return res[cou]
            })
            if (dataUser.branch) {
              arr = arr.filter(item => {
                if (dataUser.branch.id.toString() === '-1') {
                  return !item.branch || item.branch.id.toString() === '-1'
                } else {
                  return item.branch ? item.branch.id.toString() === dataUser.branch.id.toString() : false
                }
              })
            }
            for (let i = 0; i < arr.length; i++) {
              sumEntry = parseFloat(sumEntry) + parseFloat(arr[i].amount)
            }
            arr.reverse()
            arrEntry1 = arr
            sumTotalEntry = parseFloat(sumTotalEntry) + parseFloat(sumEntry)
          }
        }
        firebase.database().ref(`financial2/${dataUser.idUser}/entry`).orderByChild('date')
          .startAt(firstDay).endAt(firstDay).on('value', (snapshot) => {
          let sumEntry = 0
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                // sumEntry = parseFloat(sumEntry) + parseFloat(res[cou].amount)
                res[cou].id = cou
                return res[cou]
              })
              if (dataUser.branch) {
                arr = arr.filter(item => {
                  if (dataUser.branch.id.toString() === '-1') {
                    return !item.branch || item.branch.id.toString() === '-1'
                  } else {
                    return item.branch ? item.branch.id.toString() === dataUser.branch.id.toString() : false
                  }
                })
              }
              for (let i = 0; i < arr.length; i++) {
                sumEntry = parseFloat(sumEntry) + parseFloat(arr[i].amount)
              }
              arr.reverse()
              arrEntry2 = arr
              sumTotalEntry = parseFloat(sumTotalEntry) + parseFloat(sumEntry)
            }
          }
          arrEntryTotal = arrEntry1.concat(arrEntry2)
          commit('SET_TOTAL_INGRESO', sumTotalEntry)
          commit('SET_INGRESOS_LST', arrEntryTotal)
        })
      })
    },
    filterByDateEntry ({ commit, rootState }, { firstDay, lastDay, idUser, branch }) {
      commit('SET_TOTAL_INGRESO', 0)
      let arrEntryTotal = []
      let arrEntry1 = []
      let arrEntry2 = []
      let sumTotalEntry = 0
      firebase.database().ref(`financial/${idUser}/entry`).orderByChild('date')
        .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
        sumTotalEntry = 0
        let sumEntry = 0
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              // sumEntry = parseFloat(sumEntry) + parseFloat(res[cou].amount)
              res[cou].id = cou
              return res[cou]
            })
            if (branch) {
              arr = arr.filter(item => {
                if (branch.id.toString() === '-1') {
                  return !item.branch || item.branch.id.toString() === '-1'
                } else {
                  return item.branch ? item.branch.id.toString() === branch.id.toString() : false
                }
              })
            }
            for (let i = 0; i < arr.length; i++) {
              sumEntry = parseFloat(sumEntry) + parseFloat(arr[i].amount)
            }
            arr.reverse()
            arrEntry1 = arr
            sumTotalEntry = parseFloat(sumTotalEntry) + parseFloat(sumEntry)
          }
        }
        firebase.database().ref(`financial2/${idUser}/entry`).orderByChild('date')
          .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
          let sumEntry = 0
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                sumEntry = parseFloat(sumEntry) + parseFloat(res[cou].amount)
                res[cou].id = cou
                return res[cou]
              })
              arr.reverse()
              arrEntry2 = arr
              sumTotalEntry = parseFloat(sumTotalEntry) + parseFloat(sumEntry)
            }
          }
          arrEntryTotal = arrEntry1.concat(arrEntry2)
          commit('SET_TOTAL_INGRESO', sumTotalEntry)
          commit('SET_INGRESOS_LST', arrEntryTotal)
        })
      })
    },
    filterByDateEntryUidUser ({ commit, rootState }, { firstDay, lastDay, idUser, selectedUserUid }) {
      commit('SET_TOTAL_INGRESO', 0)
      let arrEntryTotal = []
      let arrEntry1 = []
      let arrEntry2 = []
      let sumTotalEntry = 0
      firebase.database().ref(`financial/${idUser}/entry`).orderByChild('date')
        .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
        sumTotalEntry = 0
        let sumEntry = 0
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              res[cou].id = cou
              return res[cou]
            })
            arr.reverse()
            arrEntry1 = arr.filter(item => {
              return item.selectedUserUid ? item.selectedUserUid === selectedUserUid : false
            })
            for (let i = 0; i < arrEntry1.length; i++) {
              sumEntry = parseFloat(sumEntry) + parseFloat(arrEntry1[i].amount)
            }
            sumTotalEntry = parseFloat(sumTotalEntry) + parseFloat(sumEntry)
          }
        }
        firebase.database().ref(`financial2/${idUser}/entry`).orderByChild('date')
          .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
          let sumEntry = 0
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              arr.reverse()
              arrEntry2 = arr.filter(item => {
                return item.selectedUserUid ? item.selectedUserUid === selectedUserUid : false
              })
              for (let i = 0; i < arrEntry2.length; i++) {
                sumEntry = parseFloat(sumEntry) + parseFloat(arrEntry1[i].amount)
              }
              sumTotalEntry = parseFloat(sumTotalEntry) + parseFloat(sumEntry)
            }
          }
          arrEntryTotal = arrEntry1.concat(arrEntry2)
          commit('SET_TOTAL_INGRESO', sumTotalEntry)
          commit('SET_INGRESOS_LST', arrEntryTotal)
        })
      })
    },
    filterByDateEgress ({ commit, rootState }, { firstDay, lastDay, idUser, branch }) {
      commit('SET_TOTAL_EGRESO', 0)
      firebase.database().ref(`financial/${idUser}/egress/`)
        .orderByChild('date').startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
        let sumEgress = 0
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              // sumEgress = parseFloat(sumEgress) + parseFloat(res[cou].amount)
              res[cou].id = cou
              return res[cou]
            })
            if (branch) {
              arr = arr.filter(item => {
                if (branch.id.toString() === '-1') {
                  return !item.branch || item.branch.id.toString() === '-1'
                } else {
                  return item.branch ? item.branch.id.toString() === branch.id.toString() : false
                }
              })
            }
            for (let i = 0; i < arr.length; i++) {
              sumEgress = parseFloat(sumEgress) + parseFloat(arr[i].amount)
            }
            arr.reverse()
            commit('SET_TOTAL_EGRESO', sumEgress)
            commit('SET_EGRESOS_LST', arr)
          } else {
            commit('SET_TOTAL_EGRESO', 0)
            commit('SET_EGRESOS_LST', [])
          }
        } else {
          commit('SET_TOTAL_EGRESO', 0)
          commit('SET_EGRESOS_LST', [])
        }
      })
    },
    loadEgressArr ({ commit }, dataUser) {
      // var date = new Date()
      commit('SET_TOTAL_EGRESO', 0)
      var firstDay = moment(new Date()).format('YYYY-MM-DD')
      var lastDay = new Date().toISOString().substr(0, 10)
      firebase.database().ref(`financial/${dataUser.idUser}/egress/`)
        .orderByChild('date').startAt(firstDay).endAt(lastDay).on('value', (snapshot) => {
        let sumEgress = 0
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              // sumEgress = parseFloat(sumEgress) + parseFloat(res[cou].amount)
              res[cou].id = cou
              return res[cou]
            })
            if (dataUser.branch) {
              arr = arr.filter(item => {
                if (dataUser.branch.id.toString() === '-1') {
                  return !item.branch || item.branch.id.toString() === '-1'
                } else {
                  return item.branch ? item.branch.id.toString() === dataUser.branch.id.toString() : false
                }
              })
            }
            for (let i = 0; i < arr.length; i++) {
              sumEgress = parseFloat(sumEgress) + parseFloat(arr[i].amount)
            }
            arr.reverse()
            commit('SET_TOTAL_EGRESO', sumEgress)
            commit('SET_EGRESOS_LST', arr)
          } else {
            commit('SET_TOTAL_EGRESO', 0)
            commit('SET_EGRESOS_LST', [])
          }
        } else {
          commit('SET_TOTAL_EGRESO', 0)
          commit('SET_EGRESOS_LST', [])
        }
      })
    },
    addEntryVal ({ commit, rootState }, entryData) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`financial/${rootState.user.user.uid}/entry/`).push(entryData).then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    addEgressVal ({ commit, rootState }, egressData) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`financial/${rootState.user.user.uid}/egress/`).push(egressData).then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    rmEntry ({ rootState }, idEntry) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`financial/${rootState.user.user.uid}/entry/${idEntry}`).remove().then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    rmEgress ({ rootState }, idEgress) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`financial/${rootState.user.user.uid}/egress/${idEgress}`).remove().then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    updateEntryVal ({ commit, rootState }, entryData) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`financial/${rootState.user.user.uid}/entry/${entryData.id}`).set(entryData).then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    updateEgressVal ({ commit, rootState }, egressData) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`financial/${rootState.user.user.uid}/egress/${egressData.id}`).set(egressData).then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    getEntryByProcess ({ rootState }, [documentId, idUser]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`financial/${idUser}/entry`).once('value', (snapshot) => {
          const arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              Object.keys(res).map(cou => {
                if (res[cou].documentId === documentId) {
                  arr.push({ id: cou })
                }
              })
              resolve(arr)
            } else {
              resolve(false)
            }
          } else {
            resolve(false)
          }
        }, error => {
          reject(error)
        })
      })
    },

    clearData ({ commit }) {
      commit('SET_INGRESOS_LST', [])
      commit('SET_EGRESOS_LST', [])
    },
  },
  getters: {

  },
}
